import Api from "@/services/Api";
import { Auth } from "aws-amplify";
import axios from "axios";
export default {
  checkFeeling(data) {
    return Api().post("feelings/check", data);
  },
  async createFeeling(videoId) {
    const user = await Auth.currentAuthenticatedUser();
    return axios.get(
      "https://ch2mefwbpb.execute-api.ap-south-1.amazonaws.com/V1/userinterestinsert",
      {
        params: {
          viewerId: user.username,
          videoId: videoId,
          // videoId: "ee50cff0-2779-418c-b3a4-2840850a5984.mp4",
        },
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
      }
    );
  },

  getLikedVideos(page) {
    return Api().get("feelings/videos", {
      params: {
        page,
        limit: 12,
      },
    });
  },
};
